export const SHIP_CREATE_FAIL = 'SHIP_CREATE_FAIL'
export const SHIP_CREATE_SUCCESS = 'SHIP_CREATE_SUCCESS'
export const SHIP_CREATE_REQUEST = 'SHIP_CREATE_REQUEST'

export const All_SHIP_FAIL = 'All_SHIP_FAIL'
export const All_SHIP_SUCCESS = 'All_SHIP_SUCCESS'
export const All_SHIP_REQUEST = 'All_SHIP_REQUEST'

export const NBR_SHIP_FAIL = 'NBR_SHIP_FAIL'
export const NBR_SHIP_SUCCESS = 'NBR_SHIP_SUCCESS'
export const NBR_SHIP_REQUEST = 'NBR_SHIP_REQUEST'
