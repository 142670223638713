//create fornisseur
export const CREATE_FORNISSEUR_REQUEST = 'CREATE_FORNISSEUR_REQUEST'
export const CREATE_FORNISSEUR_SUCCESS = 'CREATE_FORNISSEUR_SUCCESS'
export const CREATE_FORNISSEUR_FAIL = 'CREATE_FORNISSEUR_FAIL'

//get all fornisseur
export const ALL_FORNISSEUR_REQUEST = 'ALL_FORNISSEUR_REQUEST'
export const ALL_FORNISSEUR_SUCCESS = 'ALL_FORNISSEUR_SUCCESS'
export const ALL_FORNISSEUR_FAIL = 'ALL_FORNISSEUR_FAIL'

//login fornisseur
export const LOGIN_FORNISSEUR_REQUEST = 'LOGIN_FORNISSEUR_REQUEST'
export const LOGIN_FORNISSEUR_SUCCESS = 'LOGIN_FORNISSEUR_SUCCESS'
export const LOGIN_FORNISSEUR_FAIL = 'LOGIN_FORNISSEUR_FAIL'
